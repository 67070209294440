class RegionUtils {

  static getRegionId(regionName, regionList) {
    const region = regionList.find(item => item.name === regionName);
    return (region) ? region.ids : [];
  }

  static getRegionIdForPlatform(regionIds, platform) {
    const region = regionIds.find(item => item.platformId === platform);
    return region ? region.regionId : null;
  }

  static getSavedRegion() {
    return localStorage.getItem("userRegion");
  }

  static setSavedRegion(regionName) {
    localStorage.setItem("userRegion", regionName);
  }

}

export default RegionUtils;