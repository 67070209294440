import React from "react";
import PropTypes from "prop-types";

const CurrentTimeIndicatorArrow = ({ currentUnix, ppm, nowUnix, channelHeaderWidth }) => {

  if (!ppm || !channelHeaderWidth) {
    return null;
  }

  const position = (channelHeaderWidth + ((nowUnix - currentUnix) / 60) * ppm) - 3;

  return (
    <div className='current-time-arrow' style={{ left: position }}/>
  );
};

CurrentTimeIndicatorArrow.propTypes = {
  currentUnix: PropTypes.number,
  ppm: PropTypes.number,
  nowUnix: PropTypes.number,
  channelHeaderWidth: PropTypes.number
};

export default CurrentTimeIndicatorArrow;