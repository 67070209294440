import React, {useEffect} from "react";

const AdChannel = () => {

  useEffect(_ => {
    window.gmtLoadAds(false);
  }, []);

  return (
    <div className="ads-channel-container">
      <div id="div-gpt-ad-billboard-02" style={{
        textAlign: "center",
        maxWidth: "100%",
        maxHeight: "250px"
      }}
      >
      </div>
      <div id="div-gpt-ad-mobileBanner-02" style={{
        textAlign: "center",
        maxWidth: "100%"
      }}
      >
      </div>
    </div>
  );


};

export default AdChannel;