import React, { useEffect, useState } from "react";

const Menu = () => {

  const [openSearch, setOpenSearch] = useState(false);
  const menuItemsState = React.useRef([]);

  useEffect(() => {
    searchBoxClasses();
  }, [openSearch]);

  const searchBoxClasses = () => {
    return `search-box ${openSearch ? "open" : ""}`;
  };

  useEffect(() => {
    document.body.addEventListener("click", (e) => {
      if (!(e.target.closest(".search-box") || e.target.closest(".button-search") || e.target.closest(".search-checkbox"))) {
        setOpenSearch(false);
      }
    });

    const menu = document.querySelector(".nav-list");
    const menuItems = [];
    [...menu.children].forEach((el) => {
      menuItems.push(el.offsetWidth);
    });
    menuItemsState.current = menuItems;

    window.addEventListener("resize", renderMoreMenu);
    renderMoreMenu();
  }, []);

  const renderMoreMenu = () => {
    const menu = document.querySelector(".nav-list");
    const menuSize = parseFloat(getComputedStyle(menu, null).width.replace("px", "") - 90);

    let menuTotalWidth = 0;
    let itemThatFit = 0;

    for (let i = 0; i < menuItemsState.current.length; i++) {
      menuTotalWidth += menuItemsState.current[i];
      if (menuTotalWidth <= menuSize) {
        itemThatFit++;
        continue;
      }
      break;
    }

    const collectedSet = [];
    [...menu.children].forEach((el, index) => {
      if (index > itemThatFit - 1) {
        collectedSet.push(el);
      }

      if (!el.classList.contains("menu-item-more")) {
        el.style.display = "inline-block";
        el.style.width = "auto";
      }

    });
    collectedSet.pop();

    const subMenu = document.querySelector(".sub-menu");
    while (subMenu.firstChild) {
      subMenu.removeChild(subMenu.firstChild);
    }
    collectedSet.forEach(el => {
      const clone = el.cloneNode(true);
      clone.style.display = "block";
      subMenu.appendChild(clone);
      el.style.display = "none";
      el.style.width = "0px";
    });
  };

  return (
    <nav className="primary-nav">
      <input type="radio" name="burgerbar-expand" className="burgerbar-none-radio" id="burgerbar-none" defaultChecked/>
      <input type="radio" name="burgerbar-expand" className="burgerbar-radio" id="burgerbar"/>
      <div className="burgerbar ">
        <div className="container-fluid full burgerbar-small">
          <label className="button-menu unchecked menu-hamburger" htmlFor="burgerbar" data-component-tracked="15"/>
          <label className="button-menu checked menu-hamburger" htmlFor="burgerbar-none" data-component-tracked="15"/>
          <div className="logo" itemProp="publisher" itemScope="" itemType="https://schema.org/Organization"
               id="publisherDetails">
            <a href="https://www.whattowatch.com/" itemProp="url" data-component-tracked="24">
								<span className="site-logo " itemProp="logo" itemScope="" itemType="https://schema.org/ImageObject">
									<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0" y="0"
                       viewBox="0 0 546.3 61.1" style={{ width: "180px", height: "27px" }}>
                    <path fill="#019fde"
                          d="M284.5 35.8l-21.6 11.6V24.2l21.6 11.6zm10 0c0-12.9-10.5-23.4-23.4-23.4s-23.4 10.5-23.4 23.4 10.5 23.4 23.4 23.4c12.9 0 23.4-10.5 23.4-23.4M237.7 0h-17v13.6h-7.6V24h7.6v21.3c0 12.2 8 13.3 15.2 13.3 3.6 0 7.5-.5 10.9-.5V45.2c-1.3.2-2.6.3-3.9.3-4.3 0-5.2-1.8-5.2-5.9V24h9.1V13.6h-9.1V0z"/>
                    <path fill="#fff"
                          d="M199.9 0h-17v13.6h-7.6V24h7.6v21.3c0 12.2 8 13.3 15.2 13.3 3.6 0 7.5-.5 10.9-.5V45.2c-1.3.2-2.6.3-3.9.3-4.3 0-5.2-1.8-5.2-5.9V24h9.1V13.6h-9.1V0zm-26 29.9c0-8.6.2-17.4-21.1-17.4-10.6 0-22.4 2.1-22.9 14.6h15.8c.1-1.9 1.1-4.5 6.3-4.5 2.7 0 5.6 1.1 5.6 4.1 0 2.8-2.3 3.5-4.6 3.9-8.7 1.6-25.1 1.1-25.1 15.2 0 9.4 7.1 13.5 15.7 13.5 5.5 0 10.7-1.2 14.3-5.3h.2c-.1 1.2.2 3 .6 4.2H176c-1.9-2.8-2.1-6.6-2.1-10V29.9zm-16.3 12.3c-.3 4.6-3.3 6.9-7.1 6.9-3.1 0-5.3-2.1-5.3-4.1 0-3 2-3.9 5.7-4.8 2.3-.5 4.6-1.1 6.7-2.1v4.1zM78.3 58.1h17V34.6c0-7.1 3.9-8.6 6.8-8.6 5 0 5.6 3.6 5.6 8.8v23.3h17V27.5c0-10.2-7.3-15.1-15.3-15.1-7.2 0-11 2.5-13.9 5.9h-.2V0h-17v58.1zm-2.2-44.5H58.4l-6.2 27.5h-.3L46 13.6H30.1l-5.7 27.5h-.2l-6.6-27.5H0l13.9 44.5h17.4l6.6-27.4h.2l6.4 27.4h17.4l14.2-44.5zM499.9 58.1h17V34.6c0-7.1 3.9-8.6 6.8-8.6 5 0 5.6 3.6 5.6 8.8v23.3h17V27.5c0-10.2-7.3-15.1-15.3-15.1-7.2 0-11 2.5-13.9 5.9h-.2V0h-17v58.1zm-4.1-27.6c-1-12.3-11-18-22.7-18-13.4 0-24.1 9.4-24.1 23.4s10.7 23.3 24.1 23.3c12 0 21.5-6.8 23.2-18.9h-16.2c-.3 3.5-2.9 6.5-6.6 6.5-6.5 0-7.5-5.6-7.5-11s.9-11.1 7.5-11.1c1.8 0 3.3.5 4.3 1.5 1.1.9 1.7 2.4 1.9 4.2l16.1.1zM438.2 0h-17v13.6h-7.6V24h7.6v21.3c0 12.2 8 13.3 15.2 13.3 3.6 0 7.5-.5 10.9-.5V45.2c-1.3.2-2.6.3-3.9.3-4.3 0-5.2-1.8-5.2-5.9V24h9.1V13.6h-9.1V0zm-27.7 29.9c0-8.6.2-17.4-21.1-17.4-10.6 0-22.4 2.1-22.9 14.6h15.8c.1-1.9 1.1-4.5 6.3-4.5 2.7 0 5.6 1.1 5.6 4.1 0 2.8-2.3 3.5-4.6 3.9-8.7 1.6-25.1 1.1-25.1 15.2 0 9.4 7.1 13.5 15.7 13.5 5.5 0 10.7-1.2 14.3-5.3h.2c-.1 1.2.2 3 .6 4.2h17.3c-1.9-2.8-2.1-6.6-2.1-10V29.9zm-16.3 12.3c-.3 4.6-3.3 6.9-7.1 6.9-3.1 0-5.3-2.1-5.3-4.1 0-3 2-3.9 5.7-4.8 2.3-.5 4.6-1.1 6.7-2.1v4.1zm-26-28.6h-17.7l-6.2 27.5h-.3l-5.9-27.5h-15.9l-5.7 27.5h-.2l-6.6-27.5H292l14 44.5h17.4l6.6-27.4h.2l6.4 27.4H354l14.2-44.5z"/>
									</svg>
									<meta itemProp="url" content="https://vanilla.futurecdn.net/whattowatch/logo.svg"/>
								</span>
              <h1 itemProp="name">WhatToWatch</h1> <span className="strapline"/>
            </a>
          </div>
          <input type="checkbox" name="burgerbar-expand" className="search-checkbox" id="search-checkbox"/>
          <label className="button-search masthead-item " htmlFor="search-checkbox" data-component-tracked="22"
                 onClick={(e) => {
                   e.stopPropagation();
                   setOpenSearch(true);
                 }}>
            <span className="desktop-text">Search</span>
            <span className="icon icon-search">
								<svg className="icon-image" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000">
                  <path
                    d="M720 124a422 422 0 1 0-73 654l221 222 132-131-222-222a422 422 0 0 0-58-523zm-92 504a291 291 0 1 1-412-412 291 291 0 0 1 412 411z"/>
								</svg>
          </span>
          </label>
          <form className={searchBoxClasses()} action="https://www.whattowatch.com/search" method="GET"
                data-component-tracked="23">
            <input tabIndex="0" type="search" name="searchTerm" placeholder="Search WhatToWatch"
                   className="search-input"/>
            <button type="submit" className="search-submit">
									<span className="icon icon-search">
										<svg className="icon-image" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000">
                      <path
                        d="M720 124a422 422 0 1 0-73 654l221 222 132-131-222-222a422 422 0 0 0-58-523zm-92 504a291 291 0 1 1-412-412 291 291 0 0 1 412 411z"/>
										</svg>
									</span>
            </button>
          </form>
          <div className="flexisites-social buttons-social masthead-item">
            <a target="_self" className="icon icon-circle icon-facebook" href="https://www.facebook.com/cordcutters"
               data-component-tracked="16">
              <svg className="icon-image" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000">
                <path
                  d="M577 1000H370V500H267V328h103V224C370 84 428 0 594 0h138v172h-86c-65 0-69 25-69 69v87h156l-18 172H577v500z"/>
              </svg>
            </a>
            <a className="icon icon-circle icon-twitter" href="https://twitter.com/WhatsOnFuture"
               data-component-tracked="16">
              <svg className="icon-image" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000">
                <path
                  d="M1000 195a414 414 0 0 1-118 31c42-24 75-64 90-111a416 416 0 0 1-130 49 206 206 0 0 0-150-64c-113 0-205 91-205 202a200 200 0 0 0 5 46A586 586 0 0 1 70 137a199 199 0 0 0-28 102 201 201 0 0 0 91 168 206 206 0 0 1-93-26v3c0 98 71 179 165 198a209 209 0 0 1-54 7 210 210 0 0 1-39-3 205 205 0 0 0 192 140A415 415 0 0 1 0 809a587 587 0 0 0 315 91c377 0 583-308 583-575v-26a414 414 0 0 0 102-104v-1z"/>
              </svg>
            </a>
            <a className="icon icon-circle icon-instagram fa fa-instagram"
               href="https://www.instagram.com/whattowatchus/" data-component-tracked="16">
              <svg className="icon-image" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M12 0C8.74 0 8.333.015 7.053.072 5.775.132 4.905.333 4.14.63c-.789.306-1.459.717-2.126 1.384S.935 3.35.63 4.14C.333 4.905.131 5.775.072 7.053.012 8.333 0 8.74 0 12s.015 3.667.072 4.947c.06 1.277.261 2.148.558 2.913.306.788.717 1.459 1.384 2.126.667.666 1.336 1.079 2.126 1.384.766.296 1.636.499 2.913.558C8.333 23.988 8.74 24 12 24s3.667-.015 4.947-.072c1.277-.06 2.148-.262 2.913-.558.788-.306 1.459-.718 2.126-1.384.666-.667 1.079-1.335 1.384-2.126.296-.765.499-1.636.558-2.913.06-1.28.072-1.687.072-4.947s-.015-3.667-.072-4.947c-.06-1.277-.262-2.149-.558-2.913-.306-.789-.718-1.459-1.384-2.126C21.319 1.347 20.651.935 19.86.63c-.765-.297-1.636-.499-2.913-.558C15.667.012 15.26 0 12 0zm0 2.16c3.203 0 3.585.016 4.85.071 1.17.055 1.805.249 2.227.415.562.217.96.477 1.382.896.419.42.679.819.896 1.381.164.422.36 1.057.413 2.227.057 1.266.07 1.646.07 4.85s-.015 3.585-.074 4.85c-.061 1.17-.256 1.805-.421 2.227-.224.562-.479.96-.899 1.382-.419.419-.824.679-1.38.896-.42.164-1.065.36-2.235.413-1.274.057-1.649.07-4.859.07-3.211 0-3.586-.015-4.859-.074-1.171-.061-1.816-.256-2.236-.421-.569-.224-.96-.479-1.379-.899-.421-.419-.69-.824-.9-1.38-.165-.42-.359-1.065-.42-2.235-.045-1.26-.061-1.649-.061-4.844 0-3.196.016-3.586.061-4.861.061-1.17.255-1.814.42-2.234.21-.57.479-.96.9-1.381.419-.419.81-.689 1.379-.898.42-.166 1.051-.361 2.221-.421 1.275-.045 1.65-.06 4.859-.06l.045.03zm0 3.678c-3.405 0-6.162 2.76-6.162 6.162 0 3.405 2.76 6.162 6.162 6.162 3.405 0 6.162-2.76 6.162-6.162 0-3.405-2.76-6.162-6.162-6.162zM12 16c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4zm7.846-10.405c0 .795-.646 1.44-1.44 1.44-.795 0-1.44-.646-1.44-1.44 0-.794.646-1.439 1.44-1.439.793-.001 1.44.645 1.44 1.439z"/>
              </svg>
            </a>
            <a className="icon icon-circle icon-youtube"
               href="https://www.youtube.com/channel/UCUybqOo96IH7EpKOEOh6OmA" data-component-tracked="16">
              <svg className="icon-image" viewBox="0 0 1000 1000" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M990 298s-10-70-40-100c-38-41-81-41-100-43-140-10-350-10-350-10h-1s-209 0-350 10c-19 3-61 3-99 43-30 30-40 100-40 100S0 379 0 461v76c0 82 10 163 10 163s10 70 40 100c38 41 88 39 110 43 80 8 340 11 340 11s210 0 350-11c19-2 62-2 100-43 30-30 40-100 40-100s10-81 10-163v-76c0-82-10-163-10-163zM368 668V326l320 168-320 174z"/>
              </svg>
            </a>
            <a className="icon icon-circle icon-rss fa fa-rss" href="https://www.whattowatch.com/rss.xml"
               data-component-tracked="16">
              <svg className="icon-image" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M19.199 24C19.199 13.467 10.533 4.8 0 4.8V0c13.165 0 24 10.835 24 24h-4.801zM3.291 17.415c1.814 0 3.293 1.479 3.293 3.295 0 1.813-1.485 3.29-3.301 3.29C1.47 24 0 22.526 0 20.71s1.475-3.294 3.291-3.295zM15.909 24h-4.665c0-6.169-5.075-11.245-11.244-11.245V8.09c8.727 0 15.909 7.184 15.909 15.91z"/>
              </svg>
            </a>
          </div>
        </div>
      </div>
      <div className="wrapper">
        <div className="menuitems container-fluid full">
          <ul className="nav-list component-loaded">
            <li className="menu-item menu-item-home" style={{ visibility: "visible" }}>
              <a data-component-tracking-label="home" className="home" href="https://www.whattowatch.com/"
                 aria-label="home" data-component-tracked="13">
                <i className="icon icon-home">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 17">
                    <path d="M8 17v-6h4v6h5V9h3L10 0 0 9h3v8h5z"/>
                  </svg>
                </i>
              </a>
            </li>
            <li className="menu-item menu-item-live menu-level-1
													primary-style " style={{ visibility: "visible" }}>
              <a href="/uk-tv-guide" aria-label="UK TV Guide" data-component-tracked="13">
                UK TV Guide
              </a>
            </li>
            <li className="menu-item menu-item-live menu-level-1
													primary-style " style={{ visibility: "visible" }}>
              <a href="/tv-guide" aria-label="US TV Guide" data-component-tracked="13">
                US TV Guide
              </a>
            </li>
            <li className="menu-item menu-item-live menu-level-1
													primary-style " style={{ visibility: "visible" }}>
              <a href="/uk-shows" aria-label="UK Shows" data-component-tracked="13">
                UK Shows
              </a>
            </li>
            <li className="menu-item menu-item-live menu-level-1
													primary-style " style={{ visibility: "visible" }}>
              <a href="/us-shows" aria-label="US Shows" data-component-tracked="13">
                US Shows
              </a>
            </li>
            <li className="menu-item menu-item-live menu-level-1
													primary-style " style={{ visibility: "visible" }}>
              <a href="/soaps" aria-label="Soaps" data-component-tracked="13">
                Soaps
              </a>
            </li>
            <li className="menu-item menu-item-live menu-level-1
													primary-style " style={{ visibility: "visible" }}>
              <a href="/reviews" aria-label="Reviews" data-component-tracked="13">
                Reviews
              </a>
            </li>
            <li className="menu-item menu-item-live menu-level-1
													primary-style " style={{ visibility: "visible" }}>
              <a href="/streaming-services" aria-label="Streaming" data-component-tracked="13">
                Streaming
              </a>
            </li>
            <li className="menu-item menu-item-live menu-level-1
													primary-style " style={{ visibility: "visible" }}>
              <a href="/movies" aria-label="Movies and Film" data-component-tracked="13">
                Movies and Film
              </a>
            </li>
            <li className="menu-item menu-item-live menu-level-1
													primary-style " style={{ visibility: "visible" }}>
              <a href="/spoilers" aria-label="Spoilers" data-component-tracked="13">
                Spoilers
              </a>
            </li>
            <li className="menu-item menu-item-live menu-level-1
													primary-style " style={{ visibility: "visible" }}>
              <a href="/buying-guides" aria-label="Should I Buy?" data-component-tracked="13">
                Should I Buy?
              </a>
            </li>
            <li className="menu-item menu-item-live menu-level-1
													primary-style " style={{ visibility: "visible" }}>
              <a href="/videos" aria-label="Videos" data-component-tracked="13">
                Videos
              </a>
            </li>
            <li className="menu-item menu-item-live menu-level-1
													primary-style " style={{ visibility: "visible" }}>
              <a href="https://www.magazinesdirect.com/temp-xtv" aria-label="Subscribe TV TImes" data-component-tracked="13">
                Subscribe TV TImes
              </a>
            </li>
            <li className="menu-item menu-item-live menu-level-1
													primary-style " style={{ visibility: "visible" }}>
              <a href="https://www.magazinesdirect.com/temp-xts" aria-label="Subscribe TV &amp; Satellite Week" data-component-tracked="13">
                Subscribe TV &amp; Satellite Week
              </a>
            </li>
            <li className="menu-item menu-item-live menu-level-1
													primary-style " style={{ visibility: "visible" }}>
              <a href="https://comps.whatsontv.co.uk/" aria-label="UK Competitions" data-component-tracked="13">
                UK Competitions
              </a>
            </li>
            <li className="menu-item menu-item-more menu-level-1
					has-submenu menu-item-hover-reveal more-hide" data-component-tracked="14" style={{ visibility: "visible" }}>
              <span tabIndex="0">More
              <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 292.362 292.362">
                <g>
	                <path d="M286.935,69.377c-3.614-3.617-7.898-5.424-12.848-5.424H18.274c-4.952,0-9.233,1.807-12.85,5.424
		C1.807,72.998,0,77.279,0,82.228c0,4.948,1.807,9.229,5.424,12.847l127.907,127.907c3.621,3.617,7.902,5.428,12.85,5.428
		s9.233-1.811,12.847-5.428L286.935,95.074c3.613-3.617,5.427-7.898,5.427-12.847C292.362,77.279,290.548,72.998,286.935,69.377z"/>
              </g>
              </svg>
              </span>
              <ul className="sub-menu">

                <li className="menu-item menu-item-videos menu-level-1
						primary-style " style={{ visibility: "visible" }}>
                  <a href="https://www.whattowatch.com/videos" aria-label="Videos" data-component-tracked="13">
                    Videos
                  </a>
                </li>

              </ul>
            </li>
          </ul>

          <div className="nav-sub-set-container subsite-container">
          </div>
        </div>
      </div>
    </nav>
  );
};


export default Menu;
