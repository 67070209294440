import React, {useEffect} from "react";

import { Menu, SecondaryMenu } from "./index";

const Header = _ => {

  useEffect(() => {
    window.gmtLoadAds(true);
  }, []);

  return (
      <>
        <Menu/>
        <div id="div-gpt-ad-billboard-01" style={{
            textAlign: "center",
            maxWidth: "100%",
            height: "250px"
          }}
        >
        </div>
        <div id="div-gpt-ad-mobileBanner-01" style={{
            textAlign: "center",
            maxWidth: "100%"
          }}
        >
        </div>
        <SecondaryMenu/>
      </>
  );
};

Header.propTypes = {};

export default Header;
