import React, { useState } from "react";
import PropTypes from "prop-types";

import Col from "react-bootstrap/Col";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Form from "react-bootstrap/Form";

import { ProgramCategory, RegionUtils } from "./../../libs";


const Settings = ({ regions, setRegion, defaultRegionName, setGenreFilter, initGenreFilter }) => {

  const [currentRegion, setCurrentRegion] = useState(defaultRegionName);

  const genres = ProgramCategory.getGenresList();

  const onRegionChange = e => {
    const regionName = e.currentTarget.value;
    setCurrentRegion(regionName);
    RegionUtils.setSavedRegion(regionName);
    setRegion(RegionUtils.getRegionId(regionName, regions), regionName);
  };

  const onGenreClickHandler = e => {
    setGenreFilter(e.currentTarget.dataset.filter);
  };

  return (
    <>
      <Col className="col-xl-6 col-md-8 col-12 order-3 order-md-2">
        <Navbar id="genres-navbar-nav">
          <Nav fill variant="pills" activeKey={initGenreFilter ? initGenreFilter : ""}>
            {genres.map(genre => (
              <Nav.Item key={genre.id} data-filter={genre.filter} onClick={onGenreClickHandler}>
                <Nav.Link eventKey={genre.filter}>{genre.title}</Nav.Link>
                <div className="color-bar" style={{ "backgroundColor": genre.color }}></div>
              </Nav.Item>
            ))}
          </Nav>
        </Navbar>
      </Col>
      <Col className="col-xl-2 col-md-2 col-6 order-2 order-md-3">
        <Form.Group id="regionSelectWrapper" controlId="regionSelect">
          <Form.Control as="select" value={currentRegion} onChange={onRegionChange}>
            {regions.map(region =>
              <option key={region.name}>{region.name}</option>
            )}
          </Form.Control>
        </Form.Group>
      </Col>
    </>
  );
};


Settings.propTypes = {
  regions: PropTypes.array,
  setRegion: PropTypes.func,
  defaultRegionName: PropTypes.string,
  setGenreFilter: PropTypes.func,
  initGenreFilter: PropTypes.string
};

export default Settings;