import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import 'moment-timezone';

import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Image from "react-bootstrap/Image";
import { FaTimes } from "react-icons/fa";

import programEmptyImage from "./../assets/program_empty_image.jpg";
import emptyImage from "../assets/program_empty_image_search.jpg";

class ProgramDetails extends React.PureComponent {
  static propTypes = {
    showDescription: PropTypes.func,
    program: PropTypes.object,
    channelTitle: PropTypes.string
  };

  getProgramDescription() {
    const fields = ["long", "medium", "short"];

    for (let i = 0, l = fields.length; i < l; i++) {
      if (this.props.program.summary.hasOwnProperty(fields[i])) {
        return this.props.program.summary[fields[i]];
      }
    }

    return "";
  }

  handleOnImageError = (e) => {
    e.currentTarget.onerror = null;
    e.currentTarget.src = emptyImage;
  };
  
  getProgramCategory() {
      if(this.props.program.assets.category) {
          return " | " + this.props.program.assets.category.charAt(0).toUpperCase() + this.props.program.assets.category.slice(1);
      }
      return "";
  }


  render() {
    const time = moment.unix(this.props.program.timestamp);
    const timeEnd = moment(time).add(this.props.program.duration, "m");

    return (
      <div className="program-details">
        <div className="program-details-close" onClick={() => this.props.showDescription(null)}>Close <FaTimes/></div>

        <Row>
          <Col sm={3}>
            <Image src={this.props.program.media ? this.props.program.media : programEmptyImage}
                   alt={this.props.program.title} className="img-fluid" onError={this.handleOnImageError}/>
          </Col>
          <Col sm={9}>
            <div className="program-details-datetime">
              {time.tz('Europe/London').format("h:mm a")} - {timeEnd.tz('Europe/London').format("h:mm a")} |{" "}
              {time.tz('Europe/London').format("dddd Do MMMM YYYY")}
            </div>
            {this.props.program.landingPage ? (
              <h4 className="title"><a target="_blank" href={this.props.program.landingPage}>{this.props.program.title}</a></h4>
            ) : (
              <h4 className="title">{this.props.program.title}</h4>
            )}

            <h5 className="subTitle">{this.props.channelTitle}{this.getProgramCategory()}</h5>
            <div className="description">{this.getProgramDescription()}</div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default ProgramDetails;
