import axios from "axios";
import { RegionUtils } from "../libs";

class API {

  /**
   * get platform list
   * @returns {Promise<AxiosResponse<T> | never>}
   */
  static getPlatforms() {
    return axios.get(`${window.TIMEDIA_WHATSONTV_API_URL}/platforms`).then(res => res.data);
  }

  /**
   * get landing pages
   * @returns {Promise<AxiosResponse<any>>}
   */
  static getLandingPages(){
    return axios.get(`${window.TIMEDIA_WHATSONTV_API_URL}/landing-pages`).then(res => res.data);
  }

  /**
   * get channel list
   * @param platformId
   * @param regionId
   * @returns {Promise<AxiosResponse<T> | never>}
   */
  static getChannels(platformId = null, regionId = null) {
    let query = "";
    if (platformId) {
      query = `/${platformId}/${regionId}`;
    } else if(regionId){
      query = `/${regionId}`;
    }
    return axios.get(`${window.TIMEDIA_WHATSONTV_API_URL}/channels${query}`).then(res => res.data);
  }

  /**
   * get program schedule for channel
   * @param time
   * @param count
   * @param id
   * @returns {Promise<AxiosResponse<T> | never>}
   */
  static getScheduleForChannel({ time, count, id }) {
    const timeEnd = time + (60 * 30 * (count + 1));
    return axios
      .get(`${window.TIMEDIA_WHATSONTV_API_URL}/schedules/${id}/${time}/${timeEnd}`)
      .then(res => res.data);
  }

  /**
   * get search results
   * @param query
   * @returns {Promise<AxiosResponse<T> | never>}
   */
  static getSearchResults(query) {
    return axios
      .post(`${window.TIMEDIA_WHATSONTV_API_URL}/schedules`, { search: query })
      .then(res => res.data);
  }

  /**
   * get menu
   * @returns {Promise<AxiosResponse<T> | never>}
   */
  static getMenu() {
    return axios.get(`${window.TIMEDIA_WHATSONTV_API_URL}/brand-info`).then(res => res.data);
  }

}

export default API;
