import React, { useState } from "react";

import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import Spinner from "react-bootstrap/Spinner";
import { FaSearch } from "react-icons/fa";

import { SearchResultsList, SearchResultModal } from "./index";
import API from "./../../services/API";

const SchedulerHeader = props => {

  const [query, setQuery] = useState("");
  const [results, setResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [assetDetails, setAssetDetails] = useState(null);

  const setQueryHandler = (e) => {
    setQuery(e.currentTarget.value);
  };

  const searchHandler = () => {
    console.log("search", query);
    if (query && query.length > 0) {
      setIsLoading(true);
      API.getSearchResults(query).then(results => {
        setResults(results);
        setIsLoading(false);
      });
    }
  };

  const clearResultsHandler = () => {
    setResults([]);
  };

  const showDetailsModal = asset => {
    clearResultsHandler();
    console.log("showDetailsModal", asset);
    // API.getSearchResultDetails().then(result => {
    //   console.log(result);
    //   setAssetDetails(result);
    // });
    setAssetDetails(asset);
  };

  const hideDetailsModal = () => {
    setAssetDetails(null);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      searchHandler();
    }
  };


  return (
    <>
      <Container fluid={true} className="scheduler-header">
        <Row className="align-items-end">
          <Col lg>
            <h1>UK TV Guide & Listings</h1>
          </Col>
          <Col lg="auto" className="scheduler-search-form">
        {/* <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text>Find TV Shows:</InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl aria-label="Search query" className="shadow-none"
                           value={query} onChange={setQueryHandler} onKeyDown={handleKeyDown}/>
              {!!results.length && <SearchResultsList results={results} clearResultsHandler={clearResultsHandler}
                                                      showDetailsHandler={showDetailsModal}/>}
              <InputGroup.Append onClick={searchHandler}>
                <InputGroup.Text>
                  {!isLoading ?
                    <FaSearch/>
                    : <Spinner animation="border" size="sm" variant="secondary"/>
                  }
                </InputGroup.Text>
              </InputGroup.Append>
            </InputGroup> */}
          </Col>
        </Row>
      </Container>
      {assetDetails && <SearchResultModal asset={assetDetails} hideDetailsModal={hideDetailsModal}/>}
    </>
  );
};

SchedulerHeader.propTypes = {};

export default SchedulerHeader;
