import React from "react";
import PropTypes from "prop-types";
import Container from "react-bootstrap/Container";
import moment from "moment";

import { FaCaretLeft, FaCaretRight } from "react-icons/fa";

import Calendar from "./../../services/Calendar";

class DatepickerBar extends React.PureComponent {
  static propTypes = {
    current: PropTypes.objectOf(moment),
    currentFormat: PropTypes.string,
    changeDate: PropTypes.func
  };

  state = {
    dates: [],
    leftMargin: 64,
    transformX: 0,
    selectedIndex: 0
  };

  moveIndex = 0;

  componentDidMount() {
    const dates = Calendar.getDatesList();
    const leftMargin = document.querySelector(".arrow-button.arrow-left")
      .offsetWidth;
    this.setState({ dates, leftMargin });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.currentFormat !== this.props.currentFormat) {
      const currentDate = this.props.current.format("YYYY-MM-DD");
      const { dates } = this.state;

      const index = dates.findIndex(date => {
        return date.date === currentDate;
      });

      this.setState({ selectedIndex: index });
    }
  }

  moveBar = (event, direction) => {
    const step = 2;
    let transformX = 0;
    const items = document.querySelectorAll("#datepicker-list > li");

    if (direction === "left") {
      // prevent to move when all dates are visible
      const wrapperRect = document
        .querySelector(".datepicker-bar")
        .getBoundingClientRect();
      const lastDateElRect = items[items.length - 1].getBoundingClientRect();

      if (
        wrapperRect.x + wrapperRect.width - 2 * this.state.leftMargin >
        lastDateElRect.x + lastDateElRect.width
      ) {
        return false;
      }

      this.moveIndex += step;
      if (this.moveIndex > items.length) {
        this.moveIndex = items.length;
      }
    } else {
      this.moveIndex -= step;
      if (this.moveIndex < 0) {
        this.moveIndex = 0;
      }
    }

    if (items[this.moveIndex]) {
      for (let i = 0; i < this.moveIndex; i++) {
        transformX += items[i].offsetWidth;
      }
      this.setState({ transformX });
    }
  };

  setDate = e => {
    const target = e.currentTarget;
    const index = +target.dataset.index;
    if (index !== this.state.selectedIndex) {
      this.setState(
        {
          selectedIndex: index
        },
        () => {
          this.props.changeDate(target.dataset.date);
        }
      );
    }
  };

  render() {
    const listStyle = {
      transform:
        "translateX(" + (this.state.leftMargin - this.state.transformX) + "px)"
    };

    return (
      <Container fluid={true} className="datepicker-bar">
        <div
          className="arrow-button arrow-left"
          onClick={e => this.moveBar(e, "right")}
        >
          <FaCaretLeft/>
        </div>
        <ul id="datepicker-list" className="datepicker-list" style={listStyle}>
          {this.state.dates.map((item, index) => (
            <li key={item.date}>
              <a
                data-date={item.date}
                data-index={index}
                className={index === this.state.selectedIndex ? "selected" : ""}
                onClick={this.setDate}
                href="javascript:void(0)"
              >
                {item.dateName}
              </a>
            </li>
          ))}
        </ul>
        <div
          className="arrow-button arrow-right"
          onClick={e => this.moveBar(e, "left")}
        >
          <FaCaretRight/>
        </div>
      </Container>
    );
  }
}

export default DatepickerBar;
