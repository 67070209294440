import React from "react";
import PropTypes from "prop-types";

const CurrentTimeIndicator = ({ currentUnix, ppm, nowUnix, channelHeaderWidth }) => {

  if (!channelHeaderWidth) {
    return null;
  }
  const position = channelHeaderWidth + ((nowUnix - currentUnix) / 60) * ppm;

  return (
    <div id='current-time' style={{ left: position }}/>
  );
};

CurrentTimeIndicator.propTypes = {
  currentUnix: PropTypes.number,
  ppm: PropTypes.number,
  channelHeaderWidth: PropTypes.number,
  nowUnix: PropTypes.number
};

export default CurrentTimeIndicator;