/**
 *
 */
class ProgramCategory {

  /**
   *
   * @type {{
   *  all: {color: null, id: number, title: string},
   *  news: {color: string, id: number, title: string},
   *  drama: {color: string, id: number, title: string},
   *  comedy: {color: string, id: number, title: string},
   *  film: {color: string, id: number, title: string},
   *  sport: {color: string, id: number, title: string},
   *  soap: {color: string, id: number, title: string},
   *  kids: {color: string, id: number, title: string}
   * }}
   *
   */

  static genres = {
    all: { id: 1, title: "All", color: null, filter: "" },
    news: { id: 2, title: "Factual", color: "#A3D2D1", filter: "news" },
    sport: { id: 3, title: "Sport", color: "#B8E986", filter: "sport" },
    film: { id: 4, title: "Film", color: "#7D8083", filter: "film" },
    kids: { id: 5, title: "Kids", color: "#FFDB2F", filter: "kids" },
    soap: { id: 6, title: "Soaps", color: "#FD3951", filter: "soap" },
    music: { id: 7, title: "Music", color: "#cba5d5", filter: "music" }
  };

  /**
   *
   * @param category
   * @returns {null|*}
   */
  static getColorForCategory(category) {
    if (this.genres.hasOwnProperty(category)) {
      return this.genres[category].color;
    } else {
      return null;
    }
  }

  /**
   *
   * @returns {any[]}
   */
  static getGenresList() {
    return Object.values(this.genres);
  }

}

export default ProgramCategory;
