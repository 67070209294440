class UrlManager {

  constructor() {
    this.parseQueryString();
  }

  parseQueryString() {
    this.params = {};
    const query = window.location.search.substring(1);
    const vars = query.split("&");

    for (let i = 0, l = vars.length; i < l; i++) {
      const pair = vars[i].split("=");
      this.params[pair[0]] = pair[1];
    }
  }

  setQuery(name, value) {
    this.params[name] = value;
    this.refreshQueryString();
  }

  getQuery(name) {
    return (this.params[name]) ? this.params[name].replace('%20', ' ') : null;
  }

  refreshQueryString() {
    const queryString = Object.keys(this.params)
      .filter(key => this.params[key])
      .map(key => key + "=" + this.params[key])
      .join("&");
    // eslint-disable-next-line no-restricted-globals
    history.pushState(null, "", window.location.pathname + ((queryString) ? "?" + queryString : ""));
  }


}

export default UrlManager;