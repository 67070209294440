import React from "react";
import PropTypes from "prop-types";
import moment from "moment";

import { FaCaretLeft, FaCaretRight } from "react-icons/fa";

import Calendar from "../../services/Calendar";
import Container from "react-bootstrap/Container";
import { CurrentTimeIndicatorArrow } from "./../timeIndicator";

class TimepickerBar extends React.PureComponent {
  static propTypes = {
    current: PropTypes.objectOf(moment),
    currentUnix: PropTypes.number,
    currentFormat: PropTypes.string,
    width: PropTypes.number,
    changeHours: PropTypes.func,
    setPixelPerMinute: PropTypes.func,
    channelHeaderWidth: PropTypes.number,
    nowUnix: PropTypes.number
  };

  state = {
    hours: [],
    count: 6,
    ppm: null
  };

  componentDidMount() {
    const timeout = setTimeout(() => {
      this.getPixelsPerMinute();
      clearTimeout(timeout);
    }, 500);

    this.getPixelsPerMinute();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.width !== this.props.width) {
      let count = 6;
      if (this.props.width < 1000 && this.props.width > 840) {
        count = 4;
      } else if (this.props.width >= 640 && this.props.width <= 840) {
        count = 3;
      } else if (this.props.width < 640) {
        count = 2;
      }
      this.setState({ count }, () => this.getPixelsPerMinute());
    }
  }

  componentWillUnmount() {
  }

  getPixelsPerMinute() {
    const width = document.querySelector("#timepicker-list").offsetWidth - 6;
    const ppm = width / (this.state.count * 30);
    this.props.setPixelPerMinute({ ppm, count: this.state.count });
    this.setState({ppm});
  }

  moveBar = (event, direction) => {
    const { count } = this.state;
    this.props.changeHours({
      type: direction === "left" ? "add" : "subtract",
      count
    });
  };

  render() {
    const hours = Calendar.getHoursList(this.props.current, this.state.count);

    const listStyle = {};

    return (
      <Container fluid={true} className="timepicker-bar">
        <div
          className="arrow-button arrow-left"
          onClick={e => this.moveBar(e, "right")}
        >
          <FaCaretLeft/>
        </div>
        <ul id="timepicker-list" className="timepicker-list" style={listStyle}>
          {hours.map((item, index) => (
            <li key={item.index}>
              <a data-time={item.time} data-index={index}>
                {item.time}
              </a>
            </li>
          ))}
        </ul>
        <div
          className="arrow-button arrow-right"
          onClick={e => this.moveBar(e, "left")}
        >
          <FaCaretRight/>
        </div>
        <CurrentTimeIndicatorArrow
          currentUnix={this.props.currentUnix}
          ppm={this.state.ppm}
          nowUnix={this.props.nowUnix}
          channelHeaderWidth={this.props.channelHeaderWidth}/>
      </Container>
    );
  }
}

export default TimepickerBar;
