import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import LazyLoad from "react-lazyload";

import { Program, ProgramDetails } from "./../program";
import { ChannelLoader } from "./";
import API from "./../../services/API";
import channelLogo from "./../assets/channel_logo.png";
import LandingPagesHelper from "./../../libs/LandingPagesHelper";

class Channel extends PureComponent {
  ref = React.createRef();
  state = {
    displayDescription: false,
    displayDescriptionProgram: null,
    programLoaded: false,
    schedule: []
  };

  static propTypes = {
    currentUnix: PropTypes.number,
    channel: PropTypes.object,
    ppm: PropTypes.number,
    count: PropTypes.number,
    getOpenedDescriptionRowId: PropTypes.string,
    getOpenedDescriptionProgramId: PropTypes.string,
    setOpenedDescriptionRowId: PropTypes.func,
    loadChannels: PropTypes.number,
    genreFilter: PropTypes.string
  };

  componentDidMount() {
    this.startObserver();
  }

  startObserver() {
    const observer = new IntersectionObserver(([entry]) => {
      if (entry.intersectionRatio > 0.1 && !this.state.programLoaded) {
        observer.disconnect();
        this.setState({
          programLoaded: true
        }, () => {
          this.getScheduleForChannel();
        });
      }
    }, {
      root: null,
      rootMargin: "0px",
      threshold: 0.1
    });


    if (this.ref.current) {
      observer.observe(this.ref.current);
    }
  }


  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.props.getOpenedDescriptionRowId !==
      prevProps.getOpenedDescriptionRowId &&
      this.props.getOpenedDescriptionRowId !== this.props.channel.id
    ) {
      this.setState({
        displayDescription: false,
        displayDescriptionProgram: false
      });
    }

    if (this.props.loadChannels !== prevProps.loadChannels) {
      this.setState({
        programLoaded: false
      }, () => {
        this.startObserver();
      });
    }

  }

  getScheduleForChannel = () => {
    API.getScheduleForChannel({
      time: this.props.currentUnix,
      count: this.props.count,
      id: this.props.channel.id
    }).then(response => {
      this.setState({
        schedule: response,
        programLoaded: true
      });
    });
  };

  showDescription = programId => {
    let programDetails = null;

    if (programId) {
      programDetails = this.state.schedule.find(
        item => item.id === programId
      );

      if (programDetails) {
        programDetails.landingPage = LandingPagesHelper.getLandingPage(programDetails.title);
      }
    }

    this.setState(
      { displayDescription: true, displayDescriptionProgram: programDetails },
      () => {
        this.props.setOpenedDescription({
          rowId: programId ? this.props.channel.id : null,
          programId
        });
      }
    );
  };

  render() {
    let classNames = "pull-left channel-schedule";
    if (this.state.programLoaded && !this.state.schedule.length) {
      classNames += " channel-no-data";
    }

    return (
      <>
        <div className='row channel-row'>
          <div className='pull-left channel-header' ref={this.ref}>
            <LazyLoad height={200} offset={100} once>
              <img src={this.props.channel.media ? this.props.channel.media : channelLogo}
                   alt={this.props.channel.title}/>
            </LazyLoad>
          </div>
          <div className={classNames}>
            {(this.state.programLoaded && this.state.schedule.length) ?
              this.state.schedule.map(item => {
                if (!this.props.genreFilter || (this.props.genreFilter && item.assets.category === this.props.genreFilter && (this.props.genreFilter !== "film" || item.assets.type !== "episode"))) {
                  return (
                    <Program
                      key={item.id}
                      currentUnix={this.props.currentUnix}
                      data={item}
                      ppm={this.props.ppm}
                      showDescription={this.showDescription}
                      isSelected={
                        this.props.getOpenedDescriptionProgramId === item.id
                      }
                    />
                  );
                } else {
                  return null;
                }
              }) : (this.state.programLoaded && !this.state.schedule.length) ?
                <span>No scheduler data</span>
                : <ChannelLoader disabled={this.state.programLoaded && !this.state.schedule.length}/>}
          </div>
        </div>

        {this.state.displayDescription && this.state.displayDescriptionProgram && (
          <ProgramDetails program={this.state.displayDescriptionProgram}
                          showDescription={this.showDescription}
                          channelTitle={this.props.channel.title}/>
        )}
      </>
    );
  }
}

export default Channel;
