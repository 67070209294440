import React from "react";
import { Scheduler } from "./components/scheduler";

const App = () => {
  return (
    <div className="App">
      <Scheduler />
    </div>
  );
};

export default App;
