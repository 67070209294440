import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import "moment-timezone";

import { ProgramCategory } from "./../../libs";


class Program extends React.PureComponent {
  static propTypes = {
    currentUnix: PropTypes.number,
    data: PropTypes.object,
    ppm: PropTypes.number,
    showDescription: PropTypes.func,
    isSelected: PropTypes.bool
  };

  clickHandler = () => {
    this.props.showDescription(this.props.data.id);
  };

  render() {
    const { ppm } = this.props;
    let duration = this.props.data.duration;

    let startTime = this.props.data.timestamp - this.props.currentUnix;
    if (startTime < 0) {
      startTime = 0;
      duration =
        duration - (this.props.currentUnix - this.props.data.timestamp) / 60;
    }

    const now = moment().tz("Europe/London").unix();
    const isHighlighted = (this.props.data.timestamp <= now
      && this.props.data.timestamp + this.props.data.duration * 60 > now) ? "highlighted" : "";

    const style = {
      transform: `translateX(${((startTime / 60) * ppm)}px)`,
      // left: ~~((startTime / 60) * ppm),
      width: (duration * ppm)
    };

    const isSelected = this.props.isSelected ? "selected" : "";

    const formatedStartTime = moment
      .unix(this.props.data.timestamp)
      .tz("Europe/London")
      .format("h:mm a");

    const formatedStartEnd = moment
      .unix(this.props.data.timestamp + this.props.data.duration * 60)
      .tz("Europe/London")
      .format("h:mm a");

    let categoryBarColor = null;
    if (this.props.data.assets && this.props.data.assets.category) {
      categoryBarColor = ProgramCategory.getColorForCategory(this.props.data.assets.category);
    }

    return (
      duration > 0 && (
        <div
          className={`program-box ${isSelected} ${isHighlighted}`}
          style={style}
          onClick={this.clickHandler}
        >
          {duration <= 5 ? (
            <h4 className='text-center'>...</h4>
          ) : (
            <>
              <h4>{this.props.data.title}</h4>
              <h5>
                {formatedStartTime} - {formatedStartEnd}
              </h5>
            </>
          )}

          {categoryBarColor &&
          <div className="program-box-category-bar" style={{ backgroundColor: categoryBarColor }}></div>}

        </div>
      )
    );
  }
}

export default Program;
