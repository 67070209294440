import moment from "moment";
import 'moment-timezone';
import { cloneDeep } from "lodash-es";

class Calendar {
  /**
   *
   * @returns {Array}
   */
  static getDatesList() {
    const dates = [];
    const numberOfDays = 14;
    let date;
    let dateName;
    for (let i = 0; i < numberOfDays; i++) {
      date = moment().add(i, "d");

      if (i === 0) {
        dateName = "Today";
      } else if (i === 1) {
        dateName = "Tomorrow";
      } else if (i >= 2 && i <= 6) {
        dateName = date.format("dddd");
      } else {
        dateName = date.format("Do MMMM");
      }

      dates.push({
        date: date.format("YYYY-MM-DD"),
        dateName
      });
    }

    return dates;
  }

  /**
   *
   * @param current
   * @param count
   * @returns {Array}
   */
  static getHoursList(current, count) {
    const _current = cloneDeep(current);

    const hours = [];
    for (let i = 0; i < count; i++) {
      if (i > 0) {
        _current.add(30, "m");
      }
      hours.push({ index: i, time: _current.tz('Europe/London').format("h:mma") });
    }

    return hours;
  }
}

export default Calendar;
