import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavItem from "react-bootstrap/NavItem";
import Row from "react-bootstrap/Row";
import NavDropdown from "react-bootstrap/NavDropdown";

import { FaCaretDown } from "react-icons/fa";

import { Settings } from "./../settings";

const PlatformsList = ({
                         platforms, setPlatform, initPlatform, regions,
                         setRegion, defaultRegionName, setGenreFilter,
                         initGenreFilter
                       }) => {

  const [initPlatformKey, setInitPlatformKey] = useState(null);

  useEffect(() => {
    if (platforms && platforms.length) {
      selectActiveMenu(initPlatform);
    }
  }, [platforms]);

  const selectActiveMenu = (platformId) => {
    const platform = platforms.find(item => item.id === platformId);
    setInitPlatformKey((platform) ? platform.title : "popular");
  };

  const clickHandler = e => {
    const platformId = e.currentTarget.dataset.platform || null;
    selectActiveMenu(platformId);
    setPlatform(platformId);
  };

  return (
    <Container fluid={true}>
      <Row className="providers-row align-items-center">
        <Col className="col-xl-4 col-md-2 col-6 order-1 order-md-1">
          <Navbar expand="xl">
            <Navbar.Toggle aria-controls="providers-navbar-nav" className="buttonChannelSelect">Channels: <span
              id="channelSelected">Popular</span><FaCaretDown/></Navbar.Toggle>
            <Navbar.Collapse id="providers-navbar-nav">
              <Nav className="mr-auto" variant="pills" activeKey={initPlatformKey}>
                <Nav.Item onClick={clickHandler}>
                  <Nav.Link eventKey="popular">Popular channels</Nav.Link>
                </Nav.Item>
                {platforms.map((platform, index) => (
                  (index < 3) ?
                    <Nav.Item index={index} key={platform.id} data-platform={platform.id} onClick={clickHandler}>
                      <Nav.Link eventKey={platform.title}>{platform.title}</Nav.Link>
                    </Nav.Item>
                    : (index === 3) ?
                    <NavDropdown key='platform-nav-dropdown' title="More" id="platform-nav-dropdown">
                      {platforms.map((platform, index) => (
                        (index >= 3) &&
                        <NavDropdown.Item as={NavItem} index={index} key={platform.id}
                                          data-platform={platform.id}
                                          onClick={clickHandler}>
                          <Nav.Link eventKey={platform.title}>{platform.title}</Nav.Link>
                        </NavDropdown.Item>

                      ))}
                    </NavDropdown>
                    : null
                ))}
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </Col>
        <Settings
          regions={regions}
          setRegion={setRegion}
          defaultRegionName={defaultRegionName}
          setGenreFilter={setGenreFilter}
          initGenreFilter={initGenreFilter}/>
      </Row>
    </Container>
  );
};

PlatformsList.propTypes = {
  platform: PropTypes.array,
  setPlatform: PropTypes.func,
  initPlatform: PropTypes.string,
  setRegion: PropTypes.func,
  defaultRegionName: PropTypes.string,
  setGenreFilter: PropTypes.func,
  initGenreFilter: PropTypes.string
};

export default PlatformsList;
