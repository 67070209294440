import React from "react";
import PropTypes from "prop-types";

import { AdChannel, Channel } from "./../channel";
import { CurrentTimeIndicator } from "./../timeIndicator";

class ChannelWrapper extends React.PureComponent {
  static propTypes = {
    currentUnix: PropTypes.number,
    channels: PropTypes.array,
    ppm: PropTypes.number,
    count: PropTypes.number,
    loadChannels: PropTypes.number,
    genreFilter: PropTypes.string,
    channelHeaderWidth: PropTypes.number,
    nowUnix: PropTypes.number
  };

  state = {
    openDescriptionRowId: null,
    openDescriptionProgramId: null,
    filteredChannels: []
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.loadChannels !== prevProps.loadChannels) {
      this.setOpenedDescription({ rowId: null, programId: null });
    }

    if (this.props.channels !== prevProps.channels) {
      this.filterChannelsByGenre();
    }

    if (this.props.genreFilter !== prevProps.genreFilter) {
      this.filterChannelsByGenre();
    }
  }

  filterChannelsByGenre() {
    if (this.props.genreFilter) {
      const filteredChannels = this.props.channels.filter(channel => channel.categories.includes(this.props.genreFilter));
      this.setState({ filteredChannels });
    } else {
      this.setState({ filteredChannels: this.props.channels });
    }
  }

  setOpenedDescription = ({ rowId, programId }) => {
    this.setState({
      openDescriptionRowId: rowId,
      openDescriptionProgramId: programId
    });
  };

  render() {
    return (
      <div className="channel-wrapper">
        {this.state.filteredChannels.map((channel, index) => (
          <React.Fragment key={channel.id}>
            <Channel
              classes={index === 16 ? "channel-top-border" : ""}
              currentUnix={this.props.currentUnix}
              channel={channel}
              ppm={this.props.ppm}
              count={this.props.count}
              setOpenedDescription={this.setOpenedDescription}
              getOpenedDescriptionRowId={this.state.openDescriptionRowId}
              getOpenedDescriptionProgramId={this.state.openDescriptionProgramId}
              loadChannels={this.props.loadChannels}
              genreFilter={this.props.genreFilter}
            />
            {index === 15 && <AdChannel/>}
          </React.Fragment>
        ))}
        {!!this.state.filteredChannels.length &&
        <CurrentTimeIndicator
          currentUnix={this.props.currentUnix}
          ppm={this.props.ppm}
          channelHeaderWidth={this.props.channelHeaderWidth}
          nowUnix={this.props.nowUnix}
        />
        }
      </div>
    );
  }
}

export default ChannelWrapper;
