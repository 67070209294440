class LandingPagesHelper {
  constructor() {
    if (!LandingPagesHelper.instance) {
      this._data = {
        pages: {}
      };
      LandingPagesHelper.instance = this;
    }
    return LandingPagesHelper.instance;
  }

  setLandingPages(pages) {
    pages.forEach(item => {
      this._data.pages[item.show_title] = item.show_url;
    });
  }

  getLandingPages() {
    return this._data.pages;
  }

  getLandingPage(title) {
    return (this._data.pages[title]) ? this._data.pages[title] : false;
  }

}

const instance = new LandingPagesHelper();
Object.freeze(instance);
export default instance;
