import React from "react";
import PropTypes from "prop-types";

import moment from "moment";
import 'moment-timezone';

import Dropdown from "react-bootstrap/Dropdown";
import Row from "react-bootstrap/Row";
import Image from "react-bootstrap/Image";

import emptyImage from "./../assets/program_empty_image_search.jpg";

class SearchResultsList extends React.PureComponent {

  static propTypes = {
    clearResultsHandler: PropTypes.func,
    results: PropTypes.array,
    showDetailsHandler: PropTypes.func
  };

  componentDidMount() {
    this.dropdownRef = document.getElementById("#search-result-list");
  }

  componentWillMount() {
    document.body.addEventListener("click", this.handleClick);
  }

  componentWillUnmount() {
    document.body.removeEventListener("click", this.handleClick);
  }

  handleClick = (event) => {
    const { target } = event;

    if (target !== this.dropdownRef && !this.dropdownRef.contains(target)) {
      this.props.clearResultsHandler();
    }
  };

  handleResultItemClick = (event) => {
    const assetId = event.currentTarget.dataset.id;
    const asset = this.props.results.find(result => result.id === assetId);

    if (asset) {
      this.props.showDetailsHandler(asset);
    }

  };

  handleOnImageError = (e) => {
    e.currentTarget.onerror = null;
    e.currentTarget.src = emptyImage;
  };

  render() {
    const { results } = this.props;

    return (
      <Dropdown.Menu show={!!results.length} className="search-result-list" id="#search-result-list">
        {results.map(result => (
          <Dropdown.Item key={result.id} eventKey={result.id} data-id={result.id} onClick={this.handleResultItemClick}>
            <Row className="media search-result-list-item">
              <Image src={result.media ? result.media : emptyImage} alt={result.title} thumbnail
                     className="align-self-start mr-3" onError={this.handleOnImageError}/>
              <div className="media-body">
                <p>{result.title}</p>
                <p>
                  <small className="text-muted">{result.channelName}</small>
                </p>
                <p>
                  <small className="text-muted"> {moment(result.dateTime).tz('Europe/London').format("h:mm a, dddd, DD MMMM")}</small>
                </p>
              </div>
            </Row>
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    );
  }
};

export default SearchResultsList;