import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import 'moment-timezone';

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";

import programEmptyImage from "./../assets/program_empty_image.jpg";
import emptyImage from "../assets/program_empty_image_search.jpg";

const SearchResultModal = ({ asset, hideDetailsModal }) => {

  const show = true;
  const handleClose = () => {
    hideDetailsModal();
  };

  const getProgramDescription = () => {
    const fields = ["long", "medium", "short"];

    for (let i = 0, l = fields.length; i < l; i++) {
      if (asset.summary.hasOwnProperty(fields[i])) {
        return asset.summary[fields[i]];
      }
    }

    return "";
  };

  const handleOnImageError = (e) => {
    e.currentTarget.onerror = null;
    e.currentTarget.src = emptyImage;
  };

  return (
    <Modal show={show} className="search-result-modal" size="lg" centered onHide={handleClose}>
      <Card style={{ width: "100%" }}>
        <Card.Img variant="top" src={asset.media ? asset.media : programEmptyImage} style={{ width: "100%" }}
                  onError={handleOnImageError}/>
        <Card.Body>
          <Card.Title>{asset.title}</Card.Title>
          <Card.Subtitle className="mb-3">
            <small className="text-muted">
              {asset.channelName} | {moment(asset.dateTime).tz('Europe/London').format("h:mm a, dddd, DD MMMM")} | {asset.duration} min
            </small>
          </Card.Subtitle>
          <Card.Text>
            {getProgramDescription()}
          </Card.Text>
        </Card.Body>
        <Card.Footer className="text-right">
          <Button variant="primary" onClick={handleClose}>Close</Button>
        </Card.Footer>
      </Card>
    </Modal>
  );
};

SearchResultModal.propTypes = {
  asset: PropTypes.object,
  hideDetailsModal: PropTypes.func
};

export default SearchResultModal;