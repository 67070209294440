import React from "react";

const Footer = () => {

  return (
    <footer id="document-footer" className="container full">
      <div id="document-footer-content">
        <p>WhatToWatch is part of Future US Inc, an international media group and leading digital publisher. <a
          href="http://www.futureplc.com" target="_blank" rel="noopener noreferrer">Visit our corporate site</a>.</p>
        <nav>
          <ul>
            <li><a href="https://www.futureplc.com/terms-conditions/" target="_blank" rel="noopener noreferrer">Terms and conditions</a></li>
            <li><a href="https://www.futureplc.com/privacy-policy/" target="_blank" rel="noopener noreferrer">Privacy policy</a></li>
            <li><a href="https://www.futureplc.com/cookies-policy/" target="_blank" rel="noopener noreferrer">Cookies policy</a></li>
            <li><a href="https://www.futureplc.com/accessibility-statement/" target="_blank" rel="noopener noreferrer">Accessibility Statement</a></li>
            <li><a href="https://www.whattowatch.com/news/about-whattowatchcom" target="_blank" rel="noopener noreferrer">About Us</a></li>
            <li><a href="https://www.whattowatch.com/how-to/advertise-with-us" target="_blank" rel="noopener noreferrer">Advertise with us</a>
            </li>
            <li><a href="#">GDPR consent</a></li>
          </ul>
        </nav>
        <p>©
          Future US, Inc. 11 West 42nd Street, 15th Floor,
          New York,
          NY 10036. </p>
      </div>
    </footer>
  );
};

export default Footer;
