import React from "react";

const ChannelLoader = () => {

  return (
    <div className='channel-loader'>
      <span>Loading ...</span>
    </div>
  );
};

export default ChannelLoader;