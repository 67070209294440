import React from "react";
import moment from "moment";
import API from "./../../services/API";

import { UrlManager, RegionUtils } from "./../../libs";

import { PlatformsList } from "./../platformsList";
import { DatepickerBar } from "./../datepickerBar";
import { TimepickerBar } from "./../timepickerBar";
import { ChannelWrapper } from "./../channelWrapper";
import { SchedulerHeader } from "./../schedulerHeader";
import { Header } from "./../header";
import { cloneDeep } from "lodash-es";
import LandingPagesHelper from "./../../libs/LandingPagesHelper";
import Footer from "../footer/Footer";

class Scheduler extends React.PureComponent {
  constructor(props) {
    super(props);

    this.urlManager = new UrlManager();

    this.showHeader = !this.urlManager.getQuery("noheader");

    const savedRegionName = this.urlManager.getQuery("region") ? this.urlManager.getQuery("region") : RegionUtils.getSavedRegion();

    const current = moment()
      .minute(moment().minute() >= 30 ? 30 : 0)
      .second(0);

    this.state = {
      // current datetime
      current,
      currentFormat: current.format(),
      currentUnix: current.unix(),
      nowUnix: moment().tz("Europe/London").unix(),

      // view manager
      width: null,
      ppm: null,
      count: 6,
      channelHeaderWidth: null,

      // store
      channels: [],
      platforms: [],
      currentPlatform: this.urlManager.getQuery("platform"),
      regions: [],
      currentRegion: "de884ff9-8ab7-5edc-a16a-7f4ad9dbf166",
      regionIds: [],
      defaultRegionName: savedRegionName ? savedRegionName : "London",
      loadChannels: 0,
      genreFilter: this.urlManager.getQuery("genre")
    };


  }

  /* -----------  component life-cycles -----------*/

  componentDidMount() {
    this.updateWidth();
    window.addEventListener("resize", this.updateWidth.bind(this));
    this.getPlatformsData();
    this.getLandingPages();
    //this.getChannelsData();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWidth.bind(this));
  }

  /* -----------  API request -----------*/

  getPlatformsData = () => {
    API.getPlatforms().then(response => {
      console.log(response);
      this.setState({
        platforms: response.platforms,
        regions: response.regions
      }, () => {
        const regionIds = RegionUtils.getRegionId(this.state.defaultRegionName, this.state.regions);
        this.setState({
          regionIds
        }, () => {
          this.setPlatform(this.state.currentPlatform);
        });
      });
    });
  };

  getLandingPages = () => {
    API.getLandingPages().then(response => {
      if (response && Array.isArray(response)) {
        LandingPagesHelper.setLandingPages(response);
      }
    });
  };

  getChannelsData = () => {
    if (this.state.currentPlatform && !this.state.currentRegion) {
      return false;
    }
    API.getChannels(this.state.currentPlatform, this.state.currentRegion).then(response => {
      this.setState({
        channels: response
      });
    });
  };

  /* -----------  Current datetime management -----------*/

  /**
   * Change hours handler
   * @param obj
   */
  changeHoursHandler = obj => {

    console.log("changeHoursHandler");


    const { current } = this.state;

    const _current = cloneDeep(this.state.current);
    _current[obj.type](obj.count * 30, "m");

    const _future = moment().tz("Europe/London").add(13, "d");

    if ((_current.diff(moment().tz("Europe/London"), "hours") < 0 && obj.type === "subtract")
      || (_future.tz("Europe/London").hours(23).diff(_current, "h") < 0 && obj.type === "add")) {
      return false;
    }

    current[obj.type](obj.count * 30, "m");

    this.setState(
      { current, currentFormat: current.format(), currentUnix: current.unix() },
      () => {
        this.refreshChannels();
      }
    );
  };

  /**
   * change date handler
   * @param obj
   */
  changeDateHandler = obj => {

    console.log("changeDateHandler");

    const newDate = moment(obj, "YYYY-MM-DD");
    const current = moment(this.state.current);

    current
      .year(newDate.year())
      .month(newDate.month())
      .date(newDate.date());

    this.setState(
      { current, currentFormat: current.format(), currentUnix: current.unix() },
      () => {
        this.refreshChannels();
      }
    );
  };

  /* -----------  View management -----------*/

  /**
   * set pixelPerMinute
   * @param ppm
   * @param count
   */
  setPixelPerMinute = ({ ppm, count }) => {
    this.setState({
      ppm,
      count
    });
  };

  /**
   * update component width
   */
  updateWidth() {
    this.setState({ width: window.innerWidth });

    const channelHeaderWidthHandler = setInterval(() => {
      const channelHeader = document.querySelector(".channel-header");
      if (channelHeader) {
        clearInterval(channelHeaderWidthHandler);
        this.setState({ channelHeaderWidth: channelHeader.offsetWidth });
      }
    }, 300);

  }

  setPlatform = (platformId) => {
    this.urlManager.setQuery("platform", platformId);
    const regionId = RegionUtils.getRegionIdForPlatform(this.state.regionIds, platformId ? platformId : "f00688ce-618e-52bd-ac1b-6829e20fc236");

    this.setState({
      currentPlatform: platformId,
      currentRegion: regionId ? regionId : null
    }, () => {
      this.getChannelsData();
    });
  };

  refreshChannels = () => {
    let { loadChannels } = this.state;
    loadChannels += 1;
    this.setState({
      loadChannels
    });
  };

  setRegion = (regionIds, regionName) => {
    this.urlManager.setQuery("region", regionName);
    this.setState({
      regionIds
    }, () => {
      this.setPlatform(this.state.currentPlatform);
    });
  };

  setGenreFilter = genreFilter => {
    this.urlManager.setQuery("genre", genreFilter);
    this.setState({ genreFilter });
  };


  render() {
    return (
      <>
        {this.showHeader && <Header/>}
        {/*news feed carousel*/}
        {/*https://github.com/naver/egjs-flicking/blob/master/packages/react-flicking/README.md*/}
        <SchedulerHeader/>
        <div className="scheduler-container">
          <PlatformsList
            platforms={this.state.platforms}
            setPlatform={this.setPlatform}
            initPlatform={this.state.currentPlatform}
            regions={this.state.regions}
            setRegion={this.setRegion}
            defaultRegionName={this.state.defaultRegionName}
            setGenreFilter={this.setGenreFilter}
            initGenreFilter={this.state.genreFilter}
          />
          <DatepickerBar
            current={this.state.current}
            currentFormat={this.state.currentFormat}
            changeDate={this.changeDateHandler}
          />
          <TimepickerBar
            current={this.state.current}
            currentUnix={this.state.currentUnix}
            currentFormat={this.state.currentFormat}
            width={this.state.width}
            changeHours={this.changeHoursHandler}
            setPixelPerMinute={this.setPixelPerMinute}
            channelHeaderWidth={this.state.channelHeaderWidth}
            nowUnix={this.state.nowUnix}
          />
          <ChannelWrapper
            currentUnix={this.state.currentUnix}
            channels={this.state.channels}
            ppm={this.state.ppm}
            count={this.state.count}
            loadChannels={this.state.loadChannels}
            genreFilter={this.state.genreFilter}
            channelHeaderWidth={this.state.channelHeaderWidth}
            nowUnix={this.state.nowUnix}
          />
        </div>
        <Footer/>
      </>
    );
  }

}

export default Scheduler;
