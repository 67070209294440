import React, { useEffect, useState } from "react";

const SecondaryMenu = () => {

  return (
    <div className="trending-wrapper ">
      <div className="container-fluid full">
        <div className="trending-bar">
          <ul class="trending-items ">
            <li class="trending-item">
                <a href="/eastenders">
                  EastEnders
                </a>
              </li>
              <li class="trending-item">
                <a href="/coronation-street">
                  Coronation Street
                </a>
              </li>
              <li class="trending-item">
                <a href="/emmerdale">
                  Emmerdale
                </a>
              </li>
              <li class="trending-item">
                <a href="/hollyoaks">
                  Hollyoaks
                </a>
              </li>
              <li class="trending-item">
                <a href="/casualty">
                  Casualty
                </a>
              </li>
              <li class="trending-item">
                <a href="/doctors">
                  Doctors
                </a>
              </li>
              <li class="trending-item">
                <a href="/home-and-away">
                  Home and Away
                </a>
              </li>
              <li class="trending-item">
                <a href="https://www.whattowatch.com/tv-shows">
                  TV Shows
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
  );
};


export default SecondaryMenu;
